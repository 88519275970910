import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';

export const WORLD_MOBILE_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: 'WORLD_MOBILE',
        staging: undefined,
      },
      name: 'world-mobile',
      cmsSubdomain: {
        local: undefined,
        demo: undefined,
        staging: undefined,
        qa: undefined,
        prod: 'world-mobile-production',
      },
      ryftPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: undefined,
      },
      paypalPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: undefined,
      },
      authTenantId: {
        local: undefined,
        demo: undefined,
        staging: undefined,
        qa: undefined,
        prod: 'wn9mdp5n',
      },
      ocbDigitalBaseUrl: {
        demo: undefined,
        qa: undefined,
        prod: 'https://mobile.worldmobile.us/digital/api',
        staging: undefined,
      },
      blueBoxChatBotUrl: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        staging: undefined,
        prod: undefined,
      },
      googleTagManagerId: {
        demo: undefined,
        qa: undefined,
        staging: undefined,
        prod: 'G-C27L3KPGQQ',
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {},
  seo: {
    displayName: 'World Mobile',
  },
};
